import type {
  Configurations,
  MinMaxInterface,
  UnitFilterInterface,
} from '@store/types'

import { Configuration as ConfigurationInterface, Unit } from '@api/building'

const AVAILABLE_STATUS = 'available'

const compareRange = (range: MinMaxInterface, value: number): boolean => {
  if (range.min === 'Any' && range.max === 'Any') return true

  if (range.min === 'Any' && range.max !== 'Any')
    return value <= Number(range.max)

  if (range.min !== 'Any' && range.max === 'Any')
    return value >= Number(range.min)

  return value >= Number(range.min) && value <= Number(range.max)
}

const checkPrice = (unit: Unit, range: MinMaxInterface, showPrice: boolean) => {
  if (!showPrice) {
    return true
  }
  if (!unit.metas.price) {
    return false
  }
  return compareRange(range, Number(unit.metas.price))
}

const compareConfigurations = (
  filterConf: Configurations,
  unitConf: ConfigurationInterface
): boolean =>
  compareRange(filterConf?.bed, Number(unitConf?.bed)) &&
  compareRange(filterConf?.study, Number(unitConf?.study)) &&
  compareRange(filterConf?.bath, Number(unitConf?.bath)) &&
  compareRange(filterConf?.powderRoom, Number(unitConf?.powderRoom)) &&
  compareRange(filterConf?.car, Number(unitConf?.car))

const filterUnit = (
  unit: Unit,
  filter: UnitFilterInterface,
  showPrice: boolean,
  availableStatusLabel: string
): boolean => {
  const { apply, configurations, range, showAvailable, aspect, unitType } =
    filter

  if (!apply) {
    return true
  }

  const isInPriceRange = checkPrice(unit, range, showPrice)

  const isInConfig = compareConfigurations(
    configurations,
    unit.metas.configurations
  )
  const isAvailable =
    unit.metas.status === availableStatusLabel ||
    unit.metas.status === AVAILABLE_STATUS
  let isInAspect = true
  let isInUnitTypes = true

  if (aspect && aspect.length && unit.metas.aspect) {
    isInAspect = aspect.includes(unit.metas.aspect.toLocaleLowerCase())
  }

  if (unitType && unitType !== 'Any' && unit.metas.classification) {
    isInUnitTypes = unitType === unit.metas.classification
  }

  if (showAvailable) {
    return (
      isInAspect && isInUnitTypes && isAvailable && isInPriceRange && isInConfig
    )
  }
  return isInAspect && isInUnitTypes && isInPriceRange && isInConfig
}

export { filterUnit, checkPrice, compareConfigurations }
