import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ShortlistState, removeShortlist } from '@store/actionSlices/shortlist'
import {
  ProjectIdentity,
  ProjectType,
  RootStateFirebase,
  SessionMap,
  ShortlistPropertyType,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import DropDown from '@components/dropdown'
import LotListing from '@components/lot-listing/lot-listing'
import { Modal } from '@components/modals'
import UnitListing from '@components/unit-listing'

import { Level, Unit } from '@api/building'
import {
  ExtendedLotInterface,
  MatrixDataCollectionInterface,
  PackageInterface,
} from '@api/houseAndLand'
import {
  AgentFormData,
  ShortlistAgent,
  ShortlistDocument,
  selectFromResult,
  selectFromShortlistAgentsResult,
  useCreateShortlistAgentMutation,
  useGetAgentsListQuery,
  useGetShortlistDocumentsQuery,
  useLazyGetAgentByEmailOrIdQuery,
} from '@api/shortlisted'

import { notifyError } from '@utilities/adgroup-utilities/notifier'
import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'

import {
  CheckmarkCircleSvg,
  CloseSvg,
  FileSvg,
  LoadingSvg,
  PlusCircleSvg,
  PlusSvg,
} from '@svg/react'

import AddAgentForm from './add-agent-form'
import EmptyState from './empty-state'
import ShortlistSkeleton from './shortlist-skeleton'
import useGetShortlistData from './useGetShortlistData'

export interface ShortlistProps {
  projectIdentity: ProjectIdentity
  shortlist: ShortlistState
  session: SessionMap | undefined
}

const MAXIMUM_DOCUMENT_LENGTH_TO_SEND =
  Number(process.env.REACT_APP_MAXIMUM_DOCUMENT_LENGTH_TO_SEND) || 6

const Shortlist = ({ projectIdentity, shortlist, session }: ShortlistProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const propertyType = React.useMemo<ShortlistPropertyType>(
    () =>
      projectIdentity.type === ProjectType.HouseAndLand
        ? ShortlistPropertyType.Lot
        : ShortlistPropertyType.Unit,
    []
  )

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [showAddNewAgentForm, setShowAddNewAgentForm] = React.useState(false)
  const [promptClearAllConfirmation, setPromptClearAllConfirmation] =
    React.useState(false)

  const { filteredShortlists, queryResult, queryResultData } =
    useGetShortlistData({
      propertyType,
      session,
      projectIdentity,
      shortlists: shortlist.shortlists,
    })

  const unitsList = React.useMemo(() => {
    if (propertyType !== ShortlistPropertyType.Unit) {
      return []
    }
    const result = filteredShortlists.reduce<Unit[]>((a, c) => {
      const matchedLevel = (queryResultData as Level[]).find(
        (level) =>
          c.type === ShortlistPropertyType.Unit &&
          c.references.levelId === level.level
      )
      if (matchedLevel) {
        const matchedUnit = matchedLevel.data.find(
          (unit) => unit.id === c.propertyId
        )
        if (matchedUnit) {
          a.push(matchedUnit)
        }
      }
      return a
    }, [])
    return result
  }, [queryResultData, filteredShortlists])

  const lotsList = React.useMemo(() => {
    if (propertyType !== ShortlistPropertyType.Lot) {
      return []
    }
    const result = filteredShortlists.reduce<
      (ExtendedLotInterface & { package?: PackageInterface })[]
    >((a, c) => {
      if (c.type === ShortlistPropertyType.Lot) {
        const { matrixData, packages } = queryResultData as {
          matrixData: MatrixDataCollectionInterface
          packages: PackageInterface[]
        }

        const matchedPrecinct = matrixData?.[c.references.precinctId]
        const matchedStage = matchedPrecinct?.stages?.[c.references.stageId]
        const matchedLot = matchedStage?.lots?.find(
          (item) => item.id === c.propertyId
        )
        if (matchedPrecinct && matchedStage && matchedLot) {
          a.push({
            ...matchedLot,
            precinctId: c.references.precinctId,
            stageId: matchedStage.label,
            configuration: c.configuration,
            package: packages.find(
              (item) => item.id === c.configuration?.floorplan
            ),
          })
        }
      }
      return a
    }, [])

    return result
  }, [queryResultData, filteredShortlists])

  const documentsPayload = useGetShortlistDocumentsQuery(
    { projectId: projectIdentity.projectId },
    { selectFromResult }
  )

  const agentsPayload = useGetAgentsListQuery(
    {
      projectId: projectIdentity.projectId,
      showcaseEnabled: true,
      type: 'agent',
    },
    { selectFromResult: selectFromShortlistAgentsResult }
  )

  const [addAgentErrors, setAddAgentErrors] = React.useState<string[]>()
  const [createShortlistAgent, { isLoading: isCreatingAgent }] =
    useCreateShortlistAgentMutation()
  const [
    getShortlistAgentByEmailOrId,
    { isFetching: isFetchingShortlistAgentByEmailOrId },
  ] = useLazyGetAgentByEmailOrIdQuery()

  const [selectedDocumentIds, setSelectedDocumentIds] = React.useState<
    string[]
  >([])
  const [selectedAgent, setSelectedAgent] = React.useState<
    ShortlistAgent | undefined
  >()

  const handleUnselectDocument = (documentId: string) => {
    firebaseControlQuery.update({
      'shortlist.selectedDocumentIds': selectedDocumentIds.filter(
        (item) => item !== documentId
      ),
    })
  }

  const handleSelectDocument = (documentId: string) => {
    if (selectedDocumentIds.length >= MAXIMUM_DOCUMENT_LENGTH_TO_SEND) {
      alert(
        `You can attach a maximum of ${MAXIMUM_DOCUMENT_LENGTH_TO_SEND} items.`
      )
      return
    }

    firebaseControlQuery.update({
      'shortlist.selectedDocumentIds': [...selectedDocumentIds, documentId],
    })
  }

  const handleToggleAddNewAgentForm = React.useCallback((status: boolean) => {
    setShowAddNewAgentForm(status)
  }, [])

  const handleSelectAgent = (agentId: string) => {
    if (agentId === 'new') {
      handleToggleAddNewAgentForm(true)
      return
    }
    firebaseControlQuery.update({
      'shortlist.selectedAgentId': agentId,
    })
  }

  const handleResetAddAgentForm = () => {
    setAddAgentErrors(undefined)
    setShowAddNewAgentForm(false)
  }

  const handleAddAgent = async (
    values: Record<keyof AgentFormData, string>
  ) => {
    try {
      const existingAgentResponse = await getShortlistAgentByEmailOrId({
        emailOrId: values.email,
        projectId: projectIdentity.projectId,
      }).unwrap()
      if (existingAgentResponse?.data?.id) {
        handleResetAddAgentForm()
        setSelectedAgent(existingAgentResponse.data)
        firebaseControlQuery.update({
          'shortlist.selectedAgentId': existingAgentResponse.data.id,
        })
        return
      }
    } catch (error) {
      console.log(error)
    }
    try {
      const resp = await createShortlistAgent({
        ...values,
        projectId: projectIdentity.projectId,
      }).unwrap()
      if (!resp.data) {
        return
      }
      handleResetAddAgentForm()
      setSelectedAgent(resp.data)
      firebaseControlQuery.update({
        'shortlist.selectedAgentId': resp.data.id,
      })
    } catch (e) {
      const error = e as { data: { errors: string[]; message: string } }
      if (error?.data?.message) {
        notifyError(error?.data?.message)
      }
      if (error?.data?.errors?.length) {
        setAddAgentErrors(error.data.errors)
      }
    }
  }

  const clearSelectedAgent = () => {
    setSelectedAgent(undefined)
    firebaseControlQuery.update({
      'shortlist.selectedAgentId': '',
    })
  }

  const viewUnit = async (unit: Unit) => {
    await firebaseControlQuery.update({
      [`building.activeBlock`]: unit.blockId,
      [`building.activeLevel`]: unit.metas.level,
      [`building.activeUnit`]: unit.name,
    })

    await firebaseControlQuery.updateRoute('building')
  }

  const viewLot = async (lot: ExtendedLotInterface) => {
    await firebaseControlQuery.update({
      [`houseAndLand.activePrecinctId`]: lot.precinctId,
      [`houseAndLand.activeStageId`]: lot.stageId,
      [`houseAndLand.activeLotId`]: lot.name,
    })
    await firebaseControlQuery.updateRoute('stages')
    history.push('lot-view')
  }

  React.useEffect(() => {
    if (session) {
      const {
        shortlist: { selectedDocumentIds: firebaseSelectedDocumentIds },
      } = session

      setSelectedDocumentIds(firebaseSelectedDocumentIds)
    }
  }, [session])

  React.useEffect(() => {
    if (!session?.shortlist?.selectedAgentId) {
      return
    }

    const fetchSelectedAgent = async () => {
      if (agentsPayload.status === 'fulfilled') {
        const matchedAgent = agentsPayload.data.find(
          (i) => i.id === session?.shortlist?.selectedAgentId
        )
        if (matchedAgent) {
          setSelectedAgent(matchedAgent)
          return
        }
        try {
          const resp = await getShortlistAgentByEmailOrId({
            projectId: projectIdentity.projectId,
            emailOrId: session?.shortlist?.selectedAgentId,
          })
          if (resp?.data?.data) {
            setSelectedAgent(resp.data.data)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    fetchSelectedAgent().catch((err) => console.error(err))
  }, [
    session?.shortlist?.selectedAgentId,
    agentsPayload,
    projectIdentity.projectId,
  ])

  const agentOptions = React.useMemo(
    () => [
      {
        label: (
          <div className="group inline-flex w-full justify-between">
            <p className="group-hover:underline">Add a new agent</p>
            <p>+</p>
          </div>
        ),
        value: 'new',
        postFix: <PlusSvg className="h-5 w-5" />,
      },
      { label: 'None', value: '' },
      ...agentsPayload.data.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),
    ],
    [agentsPayload.data]
  )

  const handleRemoveAll = React.useCallback(() => {
    dispatch(removeShortlist())
    firebaseControlQuery.update({
      'shortlist.properties': [],
      'shortlist.selectedDocumentIds': [],
      'shortlist.selectedAgentId': '',
    })
    setPromptClearAllConfirmation(false)
  }, [])

  const handleClearAll = React.useCallback(() => {
    setPromptClearAllConfirmation(true)
  }, [])

  return (
    <Container>
      <DataHandler
        payload={{
          ...queryResult,
          data: queryResult.isLoaded,
        }}
        skeletonFrame={<ShortlistSkeleton />}
      >
        <div className="mx-5 h-full">
          <div className="relative flex h-top-bar w-full items-end justify-between pb-5 text-neutralColour">
            <p className="text-title font-medium leading-none">Shortlist</p>
            {shortlist.shortlists.length ? (
              <button
                type="button"
                className="text-2xl underline"
                onClick={handleClearAll}
              >
                Clear shortlist
              </button>
            ) : null}
          </div>

          {(propertyType === ShortlistPropertyType.Unit ? unitsList : lotsList)
            .length === 0 ? (
            <EmptyState />
          ) : (
            <div className="no-scrollbar flex h-page-body w-full gap-3.5 overflow-auto pb-5">
              <div className="w-1/3">
                {propertyType === ShortlistPropertyType.Unit ? (
                  <UnitListing
                    units={unitsList}
                    trigger={viewUnit}
                    hideUnitStatus
                    className="!flex flex-col"
                  />
                ) : (
                  <LotListing
                    lots={lotsList}
                    trigger={viewLot}
                    hideLotStatus
                    className="!flex flex-col"
                  />
                )}
              </div>
              <div className="w-2/3 rounded-lg bg-secondaryColour p-5 text-mainColour">
                <div className="mb-5">
                  <p className="mb-5 text-default font-bold leading-none">
                    INCLUDE
                  </p>
                  <div className="custom-scrollbar flex max-h-60 flex-col gap-5 overflow-y-auto rounded-lg bg-neutralColour p-4">
                    {!documentsPayload.isLoaded ? (
                      <div className="flex justify-center">
                        <LoadingSvg className="h-6 w-6 fill-black text-[#DDD]" />
                      </div>
                    ) : (
                      <>
                        {documentsPayload.data?.length > 0 ? (
                          <>
                            {documentsPayload.data?.map(
                              (item: ShortlistDocument) => (
                                <div
                                  key={item.id}
                                  className="flex items-center gap-3"
                                >
                                  <FileSvg
                                    size="m"
                                    className="h-5 w-4 text-gray-400"
                                  />
                                  <p className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-xl font-normal leading-none">
                                    {item.title || item.fileName}
                                  </p>
                                  {selectedDocumentIds.findIndex(
                                    (selected) => selected === item.id
                                  ) > -1 ? (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleUnselectDocument(item.id)
                                      }
                                    >
                                      <CheckmarkCircleSvg
                                        size="m"
                                        className="h-6 w-6 text-mainColour"
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleSelectDocument(item.id)
                                      }
                                    >
                                      <PlusCircleSvg
                                        size="m"
                                        className="h-6 w-6 text-mainColour"
                                      />
                                    </button>
                                  )}
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          <span className="text-xl font-normal">None</span>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="mb-5">
                  <div className="mb-5 flex items-end justify-between">
                    <p className="text-default font-bold leading-none">AGENT</p>
                  </div>
                  {!agentsPayload.isLoaded ? (
                    <div className="flex justify-center rounded-[5px] bg-secondaryColour px-5 py-3">
                      <LoadingSvg className="h-6 w-6 fill-black text-[#DDD]" />
                    </div>
                  ) : (
                    <>
                      {selectedAgent?.id ? (
                        <div className="flex max-h-40 items-center justify-between rounded-lg bg-neutralColour p-4">
                          <p className="text-lg">
                            {selectedAgent.firstName} {selectedAgent.lastName}
                          </p>
                          <button
                            className="flex items-center"
                            type="button"
                            onClick={clearSelectedAgent}
                          >
                            <CloseSvg
                              className="h-4 w-4 cursor-pointer"
                              strokeColor="currentColor"
                            />
                          </button>
                        </div>
                      ) : (
                        <DropDown
                          items={agentOptions}
                          value={
                            showAddNewAgentForm
                              ? 'new'
                              : selectedAgent?.id ?? ''
                          }
                          onSelect={(value) => handleSelectAgent(value)}
                          placeholder="None"
                          className="!bg-neutralColour !p-4 !text-mainColour"
                          optionClassName="!bg-neutralColour"
                          useValueAsKey
                        />
                      )}
                    </>
                  )}
                </div>

                <div>
                  <p className="text-center text-base font-normal">
                    Scan the QR code on the main Showcase to save shortlist.
                  </p>
                </div>
              </div>
            </div>
          )}
          <AddAgentForm
            show={showAddNewAgentForm}
            onToggle={handleToggleAddNewAgentForm}
            onConfirm={handleAddAgent}
            isProcessing={
              isFetchingShortlistAgentByEmailOrId || isCreatingAgent
            }
            errors={addAgentErrors}
          />
        </div>
        <Modal
          isVisible={promptClearAllConfirmation}
          toggleModal={setPromptClearAllConfirmation}
          modalWidth="max-w-lg"
          title="Clear shortlist"
          applyButtonText="Clear"
          closeButtonText="Cancel"
          handleApply={handleRemoveAll}
          titleClassName="text-center text-heading leading-[120%] text-mainColour"
          footerClassName="mt-5 flex flex-row-reverse items-center justify-between  gap-[15px] border-gray-200"
          cancelClassName="w-full rounded-lg border py-3 text-subHeading bg-mainColour font-medium text-white"
          okayClassName="w-full rounded-lg border py-3 text-subHeading font-normal text-[#FF0000]"
        >
          <div className="my-4 mb-12">
            <p className="leading-[120%] text-fontBlackColour opacity-60">
              Are you sure you want to clear all shortlists?
            </p>
          </div>
        </Modal>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore, shortlist }: RootStateFirebase) => ({
    shortlist,
    projectIdentity,
    session: getSession(firestore),
  })
)(Shortlist)
