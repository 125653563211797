import React from 'react'
import { connect } from 'react-redux'

import {
  PlayerControlInterface,
  ProjectIdentity,
  RootStateFirebase,
} from '@store/types'

import { SimplifiedThemeConfig } from '@api/config'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import styleUtil from '@utilities/style-util'

import {
  SpeakerSvg,
  SpeakerXMarkSvg,
  VolumeAddSvg,
  VolumeMinusSvg,
} from '@svg/react'

import ScrubBar from './scrub-bar'

export interface ComponentPropsInterface {
  galleryName: string
  playerSession: PlayerControlInterface | undefined
  projectIdentity: ProjectIdentity
  currentVideoPlayback?: string
  className?: string
}

const PlayerControl = ({
  galleryName,
  playerSession,
  projectIdentity,
  currentVideoPlayback,
  className,
}: ComponentPropsInterface) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const themeConfig: SimplifiedThemeConfig = styleUtil(projectIdentity.theme)

  const [isPlayerVisible, setIsPlayerVisible] = React.useState(false)
  const [playerState, setPlayerState] = React.useState('stop')
  const [volume, setVolume] = React.useState(0)
  const [isMuted, setisMuted] = React.useState(true)

  const handleVolumeUpClick = async () => {
    if (volume < 1) {
      const vol = Math.round((volume + 0.1) * 100) / 100
      await firebaseControlQuery.update({
        [`${galleryName}.playerControl.volume`]: vol,
        [`${galleryName}.playerControl.isMuted`]: false,
      })
    }
  }

  const handleVolumeDownClick = async () => {
    if (volume > 0) {
      const vol = Math.round((volume - 0.1) * 100) / 100
      await firebaseControlQuery.update({
        [`${galleryName}.playerControl.volume`]: vol,
        [`${galleryName}.playerControl.isMuted`]: vol === 0 ? true : isMuted,
      })
    }
  }

  const handleMuteClick = () => {
    firebaseControlQuery.updateCollection(
      `${galleryName}.playerControl.isMuted`,
      !isMuted
    )
  }

  React.useEffect(() => {
    if (playerSession) {
      const {
        isPlayerVisible: isPlayerVisibleFirebase,
        playerState: playerStateFirebase,
        volume: volumeFirebase,
        isMuted: isMutedFirebase,
      } = playerSession

      setIsPlayerVisible(isPlayerVisibleFirebase)
      setPlayerState(playerStateFirebase)
      setVolume(volumeFirebase)
      setisMuted(isMutedFirebase)
    }
  }, [playerSession])

  return (
    <div
      className={`bottom-0 z-40 w-full pb-4 transition-all duration-300 ${
        isPlayerVisible
          ? 'absolute translate-y-0 opacity-100'
          : 'pointer-events-none fixed translate-y-full opacity-0'
      } ${className}`}
    >
      <div className="rounded-lg bg-secondaryColour px-4 py-6">
        <div className="flex gap-2">
          {playerState !== 'stop' && (
            <ScrubBar
              className="flex-1"
              showSeekControls
              key={currentVideoPlayback}
              galleryName={galleryName}
              playerSession={playerSession}
              themeConfig={themeConfig}
            />
          )}

          <button type="button" onClick={() => handleMuteClick()}>
            {isMuted ? (
              <SpeakerXMarkSvg className="mr-2 h-6 w-6 cursor-pointer text-mainColour" />
            ) : (
              <SpeakerSvg className="mr-2 h-4 w-4 cursor-pointer text-mainColour" />
            )}
          </button>

          <button
            type="button"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              volume > 0 ? handleVolumeDownClick() : e.preventDefault()
            }
          >
            <VolumeMinusSvg
              className={`mr-2 h-6 w-6 cursor-pointer ${
                volume > 0 ? 'text-mainColour' : 'text-gray-300'
              }`}
            />
          </button>

          <button
            type="button"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              volume < 1 ? handleVolumeUpClick() : e.preventDefault()
            }
          >
            <VolumeAddSvg
              className={`mr-2 h-6 w-6 cursor-pointer ${
                volume < 1 ? 'text-mainColour' : 'text-gray-300'
              }`}
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(PlayerControl)
