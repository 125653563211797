import React from 'react'
import { connect } from 'react-redux'

import type {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Filter from '@components/filter'
import LevelCard from '@components/level-card'

import {
  Level,
  Unit,
  selectFromResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  MappingBlockCollection,
  Polygon,
  selectFromResult as selectInteractiveFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

import { ChevronSvg, FunnelSvg } from '@svg/react'

import LevelSkeleton from './skeleton/level-skeleton'

export interface LevelViewProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  showPrice: boolean
  hideFilter: boolean
  blocks: MappingBlockCollection
}

const ARROW_TYPE = 'arrow'
const ARROW_SMALL_TYPE = 'arrow-small'

const LevelView = ({
  projectIdentity,
  showPrice,
  session,
  hideFilter,
  blocks: blockOrders,
}: LevelViewProps): React.ReactElement => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })
  const [blocks, setBlocks] = React.useState<Array<string>>([])
  const [levels, setLevels] = React.useState<Array<Polygon>>([])
  const [activeBlock, setActiveBlock] = React.useState('')
  const [activeBlockTitle, setActiveBlockTitle] = React.useState('')
  const [isFilterOpen, toggleFilter] = React.useState(false)

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectInteractiveFromResult }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult }
  )

  const levelDataByBlock = (foundLevel: Level) =>
    blocks.length > 1
      ? foundLevel.data.filter((unit) => unit.blockId === activeBlock)
      : foundLevel.data

  const findRelevantLevel = (levelName: string) => {
    const { levels: payloadLevels } = buildingPayload
    return payloadLevels.find((res: Level) => levelName === res.level)
  }

  const countUnits = (poly: Polygon) => {
    const splicdeGroupId = poly.groupId.split('-')
    const foundLevel = findRelevantLevel(splicdeGroupId[1] || poly.groupId)

    return foundLevel ? levelDataByBlock(foundLevel).length : 0
  }
  const getFilterUnitCount = (poly: Polygon) => {
    if (session && session.building.unitFilter.apply) {
      const splicdeGroupId = poly.groupId.split('-')
      const foundLevel = findRelevantLevel(splicdeGroupId[1] || poly.groupId)

      if (!foundLevel) return 0
      return levelDataByBlock(foundLevel).filter((unit: Unit) =>
        filterUnitUtil(
          unit,
          session.building.unitFilter,
          showPrice,
          projectIdentity?.statusLabels?.available
        )
      ).length
    }
    return 0
  }

  const availableUnitCount = (poly: Polygon) => {
    if (session && session.building.unitFilter.apply) {
      return getFilterUnitCount(poly)
    }
    return countUnits(poly)
  }

  const handleLevelSelect = async (level: string) => {
    toggleFilter(false)
    const splicdeGroupId = level.split('-')
    if (splicdeGroupId.length > 1) {
      await firebaseControlQuery.update({
        [`building.activeBlock`]: splicdeGroupId[0],
        [`building.activeLevel`]: splicdeGroupId[1],
      })
    } else {
      await firebaseControlQuery.updateCollection('building.activeLevel', level)
    }
    setTimeout(async () => {
      await firebaseControlQuery.updateCollection(
        'building.sidePanelFolded',
        true
      )
      await firebaseControlQuery.updateRoute('building')
    }, 1000)
  }

  const goBackToArea = async () => {
    await firebaseControlQuery.update({
      [`building.activeBlock`]: '',
      [`building.activeLevel`]: '',
    })
    await firebaseControlQuery.updateRoute('area-view')
  }

  const handleLevelFilter = (groupId: string) => {
    let levelActive = false
    if (session && levels.length > 0) {
      const splicdeGroupId = groupId.split('-')

      const foundLevel = findRelevantLevel(splicdeGroupId[1] || groupId)

      if (!foundLevel) {
        return false
      }

      levelDataByBlock(foundLevel).forEach((unit: Unit) => {
        if (activeBlock && unit.blockId && unit.blockId !== activeBlock) {
          return
        }
        if (splicdeGroupId.length > 1) {
          if (unit.blockId !== splicdeGroupId[0]) {
            return
          }
        }
        if (!levelActive) {
          levelActive = filterUnitUtil(
            unit,
            session.building.unitFilter,
            showPrice,
            projectIdentity?.statusLabels?.available
          )
        }
      })
    }

    return levelActive
  }

  const sortOrderMap = React.useMemo(
    () =>
      new Map(
        buildingPayload?.levels?.map((item) => [item.level, item.height])
      ),
    [buildingPayload]
  )
  const filteredLevels = levels
    .filter((level) => handleLevelFilter(level.groupId))
    .sort((a, b) => {
      const groupAOrder = sortOrderMap.get(a.groupId) ?? Infinity
      const groupBOrder = sortOrderMap.get(b.groupId) ?? Infinity
      return groupAOrder - groupBOrder
    })

  const getLabel = (poly: Polygon) => {
    const POLY_TYPE = poly.type || ''
    if (POLY_TYPE === ARROW_TYPE || POLY_TYPE === ARROW_SMALL_TYPE) {
      return poly.label || poly.groupId
    }
    return `${poly.label ?? ''} ${poly.groupId}`
  }

  React.useEffect(() => {
    const { maps } = interactivePayload
    if (blocks.length === 0 && maps?.blocks) {
      setBlocks(Object.keys(maps?.blocks || {}))
    }
  }, [interactivePayload, blocks])

  React.useEffect(() => {
    const { maps } = interactivePayload
    if (blocks.length === 1) {
      const block = maps?.areaView?.polygons[0]
      const blockTitle =
        Object.keys(blockOrders)?.[0] || block?.label || block?.groupId || ''
      setActiveBlockTitle(blockTitle)
    }
  }, [blocks, blockOrders, interactivePayload.isLoaded])

  React.useEffect(() => {
    const { maps } = interactivePayload
    const blockKeys =
      buildingPayload.blockOrders.length > 1
        ? buildingPayload.blockOrders
        : Object.keys(maps?.blocks || {})

    if (blockKeys.length === 0 || Object.keys(maps).length === 0) {
      return
    }
    if (blockKeys.find((res) => res === activeBlock)) {
      setLevels(
        maps.blocks[activeBlock]?.[0]?.polygons ??
          Object.values(maps.blocks)?.[0]?.[0]?.polygons
      )
      return
    }
    setLevels(
      maps.blocks[blockKeys[0]]?.[0]?.polygons ||
        Object.values(maps.blocks)?.[0]?.[0]?.polygons
    )
  }, [
    activeBlock,
    blocks,
    interactivePayload.isLoaded,
    buildingPayload.isLoaded,
  ])

  React.useEffect(() => {
    if (activeBlock) {
      return
    }
    const { maps, status: interactivePayloadStatus } = interactivePayload
    const floorplanLength = Object.keys(maps?.floorplan || {}).length

    if (interactivePayloadStatus !== 'fulfilled' || !floorplanLength) {
      return
    }
    const { levels: buildingData, blockOrders: blockOrdersData } =
      buildingPayload

    if (buildingData.length > 0) {
      const orderedBlockKeys =
        blockOrdersData.length > 1 ? blockOrdersData : Object.keys(maps.blocks)
      const updatedActiveBlock =
        orderedBlockKeys[0] || buildingData[0]?.data[0].blockId || ''
      setActiveBlock(updatedActiveBlock)
    }
  }, [buildingPayload, interactivePayload, activeBlock])

  React.useEffect(() => {
    if (session) {
      const {
        building: { activeBlock: firebaseActiveBlock },
      } = session

      if (firebaseActiveBlock) {
        setActiveBlock(firebaseActiveBlock)
        setActiveBlockTitle(firebaseActiveBlock)
      }
    }
  }, [session])
  return (
    <Container>
      <DataHandler
        payload={{
          ...buildingPayload,
          data: buildingPayload.levels,
        }}
        skeletonFrame={<LevelSkeleton />}
      >
        {session && (
          <Filter
            toggle={toggleFilter}
            isOpen={isFilterOpen}
            firebaseUnitFilter={session.building.unitFilter}
            firebaseActiveBlock={session.building.activeBlock}
            firebaseActiveLevel={session.building.activeLevel}
            hideLevel
          />
        )}
        <div className="h-full w-full px-4">
          <div className="h-top-bar w-full items-end text-title font-medium">
            <button
              onClick={goBackToArea}
              type="button"
              className="relative flex h-[40%] items-center text-default font-normal text-neutralColour"
            >
              <ChevronSvg className="absolute -left-2.5 h-8 w-8" />

              <span className="ml-6">Buildings</span>
            </button>
            <div className="flex h-[60%] w-full items-baseline justify-between text-neutralColour">
              <div className="text-title font-medium">
                {activeBlockTitle || ''}
              </div>
              {!hideFilter && (
                <button
                  onClick={() => toggleFilter(true)}
                  type="button"
                  className="inline-flex items-center text-default"
                >
                  <span className="mr-1">Filters</span>
                  <FunnelSvg className="h-6 w-6" />
                </button>
              )}
            </div>
          </div>

          <div className="no-scrollbar h-page-body w-full overflow-auto">
            <div className="flex flex-col gap-[15px]">
              {buildingPayload.levels.length > 0 &&
              filteredLevels.length > 0 ? (
                filteredLevels.map((res: Polygon) =>
                  availableUnitCount(res) ? (
                    <LevelCard
                      key={res.groupId}
                      label={getLabel(res)}
                      handleClick={() => handleLevelSelect(res.groupId)}
                      unitCount={availableUnitCount(res)}
                      onlyShowAvailable={Boolean(
                        session?.building?.unitFilter?.showAvailable &&
                          session?.building?.unitFilter?.apply
                      )}
                    />
                  ) : null
                )
              ) : (
                <div className="flex w-full items-center justify-between rounded-lg bg-secondaryColour px-[30px] py-9 text-heading font-medium text-neutralColour">
                  No level found
                </div>
              )}
            </div>
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    projectIdentity: { showPrice, hideFilter },
    projectIdentity,
    firestore,
    blockLevel: { blocks },
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectIdentity,
    showPrice,
    hideFilter,
    blocks,
  })
)(LevelView)
