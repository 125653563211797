import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { setShortlists } from '@store/actionSlices/shortlist'
import {
  InteractivePlanType,
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import { Status } from '@components/data-handler/data-handler'
import FILTER_INITIAL_STATE_HOUSE_AND_LAND from '@components/filter/filterStateHouseAndLand'
import Filter from '@components/filter/house-and-land-filter'
import useGetFilterData from '@components/filter/use-get-filter-data'
import FloorPlateGallery from '@components/floor-plate-gallery'
import LotListing from '@components/lot-listing'
import {
  PanoramaType,
  PannellumDataInterface as PanoramicDataInterface,
} from '@components/showcase-pannellum/types'

import FloorGalleryTab from '@pages/building/floor-gallery-tab'
import NoLotCard from '@pages/stage/no-lot-card'

import {
  FloorPlateGalleryInterface,
  selectFromResult as selectFromFloorGalleryResult,
  useGetFloorPlateGalleryQuery,
} from '@api/floor-plate-gallery'
import {
  ExtendedLotInterface,
  ExtendedStageInterface,
  SummaryCollectionInterface,
  selectPackageSummaryFromResult,
  useGetPackageSummaryQuery,
} from '@api/houseAndLand'
import {
  Polygon,
  selectFromResult as selectInteractiveFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'
import {
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'
import { getTabIndexByKey, getTabKeyByIndexBy } from '@utilities/floor-tab'
import { areObjectsDeepEqual } from '@utilities/helper'

import { ChevronSvg, FunnelSvg, PanoramaSvg } from '@svg/react'

import filterPackageSummary from '@adUtilities/filter-package-summary'

import StageSkeleton from './stages-skeleton'

interface PolygonsCollection {
  [key: string]: Polygon[]
}

export interface StageProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  packageSummary: SummaryCollectionInterface | null
  precinctLabel: string
}

const ARROW_TYPE = 'arrow'
const ARROW_SMALL_TYPE = 'arrow-small'

const Stage = ({
  session,
  projectIdentity,
  packageSummary,
  precinctLabel,
}: StageProps): React.ReactElement => {
  const history = useHistory()
  const dispatch = useDispatch()
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [stagePolygonsCollection, setStagePolygonsCollection] =
    useState<PolygonsCollection>({})

  const [activePrecinct, setActivePrecinct] = useState('')
  const [filterActivePrecinct, setFilterActivePrecinct] = useState('')

  const [activeStage, setActiveStage] = useState<string>('')
  const [activeStageLabel, setActiveStageLabel] = useState<string>('')

  const [floorGalleryActiveTab, setFloorGalleryActiveTab] = useState('floor')
  const [isFilterOpen, toggleFilter] = useState<boolean>(false)

  const hasScrolled = useRef<boolean>(false)
  const lotListingContainerRef = useRef<HTMLDivElement>(null)
  const stageRefs = useRef<(HTMLDivElement | null)[]>([])
  const previousScrollPosition = useRef(0)

  const filterPayload = useGetFilterData({
    projectName: projectIdentity.projectId,
    precinctId: filterActivePrecinct,
  })

  const interactivePayload = useGetInteractivePlanQuery(
    {
      projectName: projectIdentity.projectId,
      type: InteractivePlanType.Precinct,
      slug: filterActivePrecinct,
    },
    {
      selectFromResult: selectInteractiveFromResult,
      skip: !filterActivePrecinct,
    }
  )

  const packageSummaryPayload = useGetPackageSummaryQuery(
    {
      projectName: projectIdentity.projectId,
      precinctId: activePrecinct,
      lots: true,
    },
    {
      selectFromResult: selectPackageSummaryFromResult,
      skip: !!packageSummary,
    }
  )

  const floorGalleryPayload = useGetFloorPlateGalleryQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromFloorGalleryResult }
  )

  const { floorPlateGalleryData } = floorGalleryPayload

  const { panoramicData } = useGetPanoramicQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromPanoramicResult }
  )

  useEffect(() => {
    const { maps } = interactivePayload

    if (
      filterActivePrecinct &&
      maps.precinct &&
      Object.keys(maps.precinct)?.length > 0 &&
      maps.precinct?.[filterActivePrecinct] &&
      !stagePolygonsCollection[filterActivePrecinct]
    ) {
      setStagePolygonsCollection({
        ...stagePolygonsCollection,
        [filterActivePrecinct]:
          maps.precinct[filterActivePrecinct]?.[0]?.polygons || [],
      })
    }
  }, [interactivePayload, filterActivePrecinct])

  const apiStatus = useMemo(() => {
    const { FULFILLED, REJECTED, PENDING } = Status
    const { status: filterStatus } = filterPayload
    const { status: interactiveStatus } = interactivePayload
    const { status: packageSummaryStatus } = packageSummaryPayload

    if (
      filterStatus === FULFILLED &&
      interactiveStatus === FULFILLED &&
      (packageSummary !== null || packageSummaryStatus === FULFILLED)
    ) {
      return FULFILLED
    }

    if (
      filterStatus === REJECTED ||
      interactiveStatus === REJECTED ||
      packageSummaryStatus === REJECTED
    ) {
      return REJECTED
    }

    return PENDING
  }, [
    filterPayload.status,
    interactivePayload.status,
    packageSummaryPayload.status,
  ])

  const handleLotClick = async (lot: ExtendedLotInterface) => {
    toggleFilter(false)

    await firebaseControlQuery.update({
      [`houseAndLand.activeLotId`]: lot.name,
      [`houseAndLand.activeStageId`]: lot.stageId,
    })
    history.push('lot-view')
  }

  const handleBackButtonPress = async () => {
    await firebaseControlQuery.update({
      [`activeRoute`]: 'precinct',
    })

    await firebaseControlQuery.update({
      [`houseAndLand.activeStageId`]: '',
      [`floorGallery.galleryControl.activeTabIndex`]: 0,
      [`floorGallery.playerControl.playerState`]: 'stop',
      [`floorGallery.playerControl.isPlayerVisible`]: false,
    })
  }

  const findPanoramic = (
    type: PanoramaType.Precinct | PanoramaType.Stage,
    targetName: string
  ) =>
    panoramicData.find(
      (data: PanoramicDataInterface) =>
        data.type === type &&
        (data.panoramaGroup || '')?.toString() === targetName
    )

  const handleFloorGalleryTabClick = async (tabKey: string) => {
    await firebaseControlQuery.update({
      [`floorGallery.galleryControl.activeItemID`]: 0,
      [`floorGallery.playerControl.playerState`]: 'stop',
      [`floorGallery.playerControl.isPlayerVisible`]: false,
      [`floorGallery.galleryControl.activeTabIndex`]: getTabIndexByKey(tabKey),
    })
  }

  const floorGalleryItemsCount = (): number => {
    if (!activeStage) return 0

    const matchesStage = (item: FloorPlateGalleryInterface) =>
      item.stage.toString() === activeStage.toString()

    const matchesPrecinct = (item: FloorPlateGalleryInterface) =>
      item.precinct.toString() === activePrecinct.toString()

    const matchingFloorPlate = floorPlateGalleryData.find(
      (item: FloorPlateGalleryInterface) =>
        activePrecinct
          ? matchesStage(item) && matchesPrecinct(item)
          : matchesStage(item)
    )

    const floorAssets = matchingFloorPlate?.assets ?? []

    return floorAssets.length
  }

  const setStageRef = (el: HTMLDivElement | null, index: number) => {
    stageRefs.current[index] = el
  }

  const getLabel = (poly: Polygon) => {
    const POLY_TYPE = poly.type || ''
    if (POLY_TYPE === ARROW_TYPE || POLY_TYPE === ARROW_SMALL_TYPE) {
      return poly.label || poly.groupId
    }
    return `${poly.label ?? ''} ${poly.groupId}`
  }

  const stages = useMemo(() => {
    const activePackageSummary = packageSummary?.[activePrecinct]
    if (!activePackageSummary) {
      return []
    }

    let packageStages = activePackageSummary.stages
      ? Object.values(activePackageSummary.stages)
      : []

    if (session?.houseAndLand?.lotFilter?.apply) {
      const lotFilter = session?.houseAndLand?.lotFilter

      packageStages = Object.values(
        filterPackageSummary(
          packageSummary[activePrecinct],
          {
            ...lotFilter,
            storey: lotFilter.storey.map((item) => Number(item)),
          },
          projectIdentity?.statusLabels?.available
        )?.stages ?? {}
      )
      if (!session.houseAndLand.lotFilter.anyStage) {
        packageStages = packageStages.filter((res) => res.label === activeStage)
      }
    }

    return packageStages.map((stage) => {
      const polygon = stagePolygonsCollection[activePrecinct]?.find(
        (polygon) => polygon.groupId === stage.label
      )

      const mappedLots = stage.lots.map((lot) => ({
        ...lot,
        stageId: stage.label,
        precinctId: activePrecinct,
      }))

      return {
        ...stage,
        label: polygon ? getLabel(polygon) : '',
        groupId: polygon?.groupId || '',
        lots: mappedLots,
      }
    })
  }, [
    packageSummary,
    activePrecinct,
    activeStage,
    stagePolygonsCollection,
    session?.houseAndLand?.lotFilter?.apply,
  ])

  const getActiveStageGroupId = () =>
    stages.find((stage) => stage.label === activeStageLabel)?.groupId

  const shouldShowPanoramic = (): boolean => {
    if (activeStageLabel !== '') {
      return (
        findPanoramic(PanoramaType.Stage, getActiveStageGroupId() || '') !==
        undefined
      )
    }

    if (activePrecinct !== '') {
      return (
        findPanoramic(
          PanoramaType.Precinct,
          activePrecinct?.toString() || ''
        ) !== undefined
      )
    }

    return false
  }

  const navigateToPanoramic = async () => {
    if (activeStage !== activeStageLabel) {
      await firebaseControlQuery.updateCollection(
        'houseAndLand.activeStageId',
        getActiveStageGroupId() || ''
      )
    }

    await firebaseControlQuery.updateRoute('panoramic')

    history.push('panoramic')
  }

  const scrollToActiveStage = () => {
    const activeStageElement = document.querySelector(
      '.active-stage'
    ) as HTMLDivElement

    if (activeStageElement && lotListingContainerRef?.current) {
      const containerRect =
        lotListingContainerRef.current.getBoundingClientRect()
      const activeStageRect = activeStageElement.getBoundingClientRect()

      const isInViewport =
        activeStageRect.top >= containerRect.top &&
        activeStageRect.bottom <= containerRect.bottom

      if (isInViewport) {
        // For cases where there isn't enough space to scroll
        const firstStageLabel = stages.length > 0 ? stages[0].label : ''
        setActiveStageLabel(firstStageLabel)
        return
      }

      const offsetTop =
        activeStageElement.offsetTop - lotListingContainerRef.current.offsetTop

      lotListingContainerRef.current.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      })

      hasScrolled.current = true
    }
  }

  const getStagePolygonLabel = (activeStage: string) => {
    const polygon = stagePolygonsCollection[activePrecinct]?.find(
      (polygon) => polygon.groupId === activeStage
    )

    const label = polygon ? getLabel(polygon) : ''
    setActiveStageLabel(label)
  }

  const handleStageSelect = async (stage: ExtendedStageInterface) => {
    getStagePolygonLabel(stage.groupId)

    await firebaseControlQuery
      .update({
        [`houseAndLand.activeStageId`]: stage.groupId,
      })
      .then(() => {
        scrollToActiveStage()
      })
  }

  const handleActiveStageLabelClick = async () => {
    const selectedStage = stages.find(
      (item) => item.label === activeStageLabel
    ) as ExtendedStageInterface

    if (!selectedStage) {
      return
    }

    await handleStageSelect(selectedStage)
  }

  useEffect(() => {
    const activePackageSummary = packageSummary?.[activePrecinct]

    if (!activePackageSummary || !stagePolygonsCollection) {
      return
    }

    if (activeStage) {
      getStagePolygonLabel(activeStage)
    }
  }, [activeStage, packageSummary, stagePolygonsCollection, activePrecinct])

  useEffect(() => {
    if (hasScrolled.current) {
      return () => {}
    }

    if (!activeStageLabel || stages.length === 0) {
      return () => {}
    }

    const timer = setTimeout(() => scrollToActiveStage(), 0)

    return () => clearTimeout(timer)
  }, [activeStageLabel, stages])

  useEffect(() => {
    if (!session || !session.connected) {
      return
    }

    const {
      houseAndLand: {
        activePrecinctId: firebaseActivePrecinctId,
        activeStageId: firebaseActiveStageId,
      },
      floorGallery: {
        galleryControl: { activeTabIndex: firebaseActiveFloorGalleryTabIndex },
      },
    } = session

    setActivePrecinct(firebaseActivePrecinctId)
    setFilterActivePrecinct(firebaseActivePrecinctId)
    setActiveStage(firebaseActiveStageId)

    setFloorGalleryActiveTab(
      getTabKeyByIndexBy(firebaseActiveFloorGalleryTabIndex)
    )
  }, [
    session?.connected,
    session?.houseAndLand?.activePrecinctId,
    session?.houseAndLand?.activeStageId,
    session?.houseAndLand?.activeLotId,
    session?.floorGallery?.galleryControl?.activeTabIndex,
  ])

  useEffect(() => {
    const handleScroll = () => {
      if (!lotListingContainerRef.current) return

      const scrollPosition = lotListingContainerRef.current.scrollTop
      const containerOffset = lotListingContainerRef.current.offsetTop - 100
      const isScrollingDown = scrollPosition > previousScrollPosition.current

      stageRefs.current.forEach((section, index) => {
        if (section) {
          const sectionTop = section.offsetTop - containerOffset
          const sectionHeight = section.clientHeight

          if (isScrollingDown) {
            const isInViewport =
              scrollPosition >= sectionTop - sectionHeight / 2 &&
              scrollPosition < sectionTop + sectionHeight / 2

            if (isInViewport && section.id !== activeStageLabel) {
              setActiveStageLabel(section.id)
              shouldShowPanoramic()
            }
          } else {
            const previousSection = stageRefs.current[index - 1]
            const isPreviousSectionInViewport =
              previousSection &&
              scrollPosition < sectionTop - 15 &&
              scrollPosition >= previousSection.offsetTop

            if (
              isPreviousSectionInViewport &&
              previousSection.id !== activeStageLabel
            ) {
              setActiveStageLabel(previousSection.id)
              shouldShowPanoramic()
            }
          }
        }
      })

      previousScrollPosition.current = scrollPosition
    }

    const container = lotListingContainerRef.current
    container?.addEventListener('scroll', handleScroll)

    return () => {
      container?.removeEventListener('scroll', handleScroll)
    }
  }, [activeStageLabel])

  useEffect(() => {
    if (!session?.connected) {
      return
    }
    dispatch(setShortlists(session?.shortlist?.properties || []))
  }, [session?.connected])

  useEffect(() => {
    if (!hasScrolled.current) {
      return () => {}
    }

    const isLotFilterEqual = areObjectsDeepEqual(
      session?.houseAndLand?.lotFilter,
      FILTER_INITIAL_STATE_HOUSE_AND_LAND
    )

    if (!isLotFilterEqual) {
      return () => {}
    }

    const timer = setTimeout(() => scrollToActiveStage(), 0)

    return () => clearTimeout(timer)
  }, [session?.houseAndLand?.lotFilter])

  return (
    <Container>
      <DataHandler
        message="There are no available lots."
        payload={{
          ...packageSummaryPayload,
          data: stages,
          status: apiStatus,
        }}
        showDefaultNotFound={false}
        skeletonFrame={<StageSkeleton />}
      >
        {session && (
          <Filter
            key={session.houseAndLand.activeStageId}
            toggle={toggleFilter}
            isOpen={isFilterOpen}
            activePrecinct={filterActivePrecinct}
            setActivePrecinct={setFilterActivePrecinct}
            firebaseLotFilter={session.houseAndLand.lotFilter}
            firebaseActiveStage={session.houseAndLand.activeStageId}
            isStageFetching={filterPayload.isFetching}
          />
        )}
        <div className="flex h-screen w-full flex-col px-4">
          <div className="sticky top-0 z-10 w-full flex-shrink-0 py-3 text-neutralColour">
            <div className="flex max-h-9 flex-1 items-start justify-between text-default font-normal">
              {/* Back Navigation Button */}
              <button
                type="button"
                className="relative inline-flex items-center"
                onClick={handleBackButtonPress}
              >
                <ChevronSvg className="absolute -left-2.5 h-8 w-8" />
                <span className="ml-6">{precinctLabel || activePrecinct}</span>
              </button>

              {/* Floor and Gallery Tab */}
              {floorGalleryItemsCount() > 0 && (
                <FloorGalleryTab
                  activeTab={floorGalleryActiveTab}
                  handleClick={handleFloorGalleryTabClick}
                />
              )}
            </div>

            {/* Title, Filter and Panoramic Button */}
            <div className="flex max-h-14 items-center items-baseline justify-between">
              <button type="button" onClick={handleActiveStageLabelClick}>
                <span className="text-title font-medium">
                  {activeStageLabel}
                </span>
              </button>
              <div className="flex items-center gap-5">
                {/* Panoramic Button */}
                {shouldShowPanoramic() && (
                  <button
                    type="button"
                    className="flex items-center gap-1.5 p-5"
                    onClick={navigateToPanoramic}
                  >
                    <span className="text-[25px] font-normal leading-7">
                      Panoramic view
                    </span>
                    <PanoramaSvg className="h-6 w-6" />
                  </button>
                )}

                {/* Filter Button */}
                {!projectIdentity.hideFilter &&
                  floorGalleryActiveTab === 'floor' && (
                    <button
                      type="button"
                      onClick={() => toggleFilter(true)}
                      className="inline-flex items-center text-default"
                    >
                      <span className="mr-1">Filters</span>
                      <FunnelSvg className="h-6 w-6" />
                    </button>
                  )}
              </div>
            </div>
          </div>

          <div
            ref={lotListingContainerRef}
            className="custom-wrapper no-scrollbar w-full flex-grow overflow-auto"
          >
            <TransitionGroup>
              <CSSTransition
                key={floorGalleryActiveTab}
                classNames={
                  floorGalleryActiveTab === 'gallery'
                    ? 'transition-slide-left'
                    : 'transition-slide-right'
                }
                timeout={300}
                exit={false}
                onEntered={(node: HTMLElement) =>
                  node.classList.remove('transition-slide-left-enter-done')
                }
              >
                <>
                  {session &&
                  floorGalleryActiveTab === 'floor' &&
                  stages.length > 0 ? (
                    stages.map(
                      (stage: ExtendedStageInterface, index: number) => (
                        <section key={stage.label} className="mb-3.5">
                          <div
                            id={stage.label}
                            ref={(el) => setStageRef(el, index)}
                            className={`w-full py-1 ${
                              index === 0 ? 'hidden' : ''
                            }`}
                          >
                            <button
                              type="button"
                              onClick={() => handleStageSelect(stage)}
                            >
                              <span className="text-[52px] font-medium text-neutralColour">
                                {stage.label}
                              </span>
                            </button>
                          </div>
                          <LotListing
                            className={`${
                              stage.label === activeStageLabel
                                ? 'active-stage'
                                : ''
                            }`}
                            lots={stage.lots}
                            trigger={handleLotClick}
                            disableSoldLot={projectIdentity?.disableSoldUnit}
                            enableUnshortlistAll
                          />
                        </section>
                      )
                    )
                  ) : (
                    <NoLotCard />
                  )}

                  {floorGalleryActiveTab === 'gallery' &&
                    floorPlateGalleryData.length > 0 && (
                      <FloorPlateGallery
                        floorPlateGalleryData={floorPlateGalleryData}
                        floorGalleryControlSession={session?.floorGallery}
                        activePrecinct={activePrecinct}
                        activeStage={activeStage}
                      />
                    )}
                </>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    projectIdentity,
    firestore,
    houseAndLand: { packageSummary },
    appConfig: { precinctLabel },
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectIdentity,
    packageSummary,
    precinctLabel,
  })
)(Stage)
