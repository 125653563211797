import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase } from '@store/types'

import LotCard from '@components/lot-card'
import { Modal } from '@components/modals'

import NoLotCard from '@pages/stage/no-lot-card'

import {
  ExtendedLotInterface,
  LotInterface,
  PackageInterface,
} from '@api/houseAndLand'

type LotWithPackage = Array<
  ExtendedLotInterface & { package?: PackageInterface }
>

export interface LotListingProps {
  lots: LotWithPackage
  showPrice: boolean
  hideSoldPrice: boolean
  trigger?: (lot: ExtendedLotInterface) => void
  hideLotStatus?: boolean
  disableSoldLot?: boolean
  enableUnshortlistAll?: boolean
  className?: string
}

const LotListing = ({
  lots,
  showPrice,
  hideSoldPrice,
  trigger,
  hideLotStatus,
  disableSoldLot,
  className,
  enableUnshortlistAll,
}: LotListingProps): React.ReactElement => {
  const [showAlertModal, toggleAlertModal] = useState<boolean>(false)
  const [alertModalMessage, setAlertModalMessage] = useState<string>('')

  const handleTrigger = useCallback(
    (precinctId: string, stageId: string) => (lot: LotInterface) => {
      trigger?.({
        precinctId,
        stageId,
        ...lot,
      })
    },
    [trigger]
  )

  const setShortlistErrorMessage = (message: string) => {
    setAlertModalMessage(message)
    toggleAlertModal(true)
  }

  return (
    <>
      <div
        className={`custom-scrollbar grid h-full grid-cols-2 gap-[15px] overflow-y-auto ${className}`}
      >
        {lots.length > 0 ? (
          lots.map((lot) => (
            <LotCard
              key={lot.id}
              lot={lot}
              package={lot.package}
              showPrice={showPrice}
              hideSoldPrice={hideSoldPrice}
              hideLotStatus={hideLotStatus}
              disableSoldLot={disableSoldLot}
              trigger={handleTrigger(lot.precinctId, lot.stageId)}
              setShortlistErrorMessage={setShortlistErrorMessage}
              enableUnshortlistAll={enableUnshortlistAll}
            />
          ))
        ) : (
          <NoLotCard />
        )}
      </div>
      <Modal
        isVisible={showAlertModal}
        toggleModal={toggleAlertModal}
        message={alertModalMessage}
        modalWidth="max-w-lg"
        title="Message"
      />
    </>
  )
}

export default connect(
  ({ projectIdentity: { showPrice, hideSoldPrice } }: RootStateFirebase) => ({
    showPrice,
    hideSoldPrice,
  })
)(LotListing)
