import React from 'react'
import { useDispatch } from 'react-redux'

import {
  ShortlistState,
  addShortlist,
  filterShortlist,
  setShortlists,
} from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  ProjectIdentity,
  ShortlistItemInterface,
  ShortlistPropertyType,
} from '@store/types'

import FirebaseControlQuery from './firebase-control-query'

interface ComponentProps {
  property: ShortlistItemInterface
  shortlist: ShortlistState
  setShortlistErrorMessage: (arg: string) => void
  toggleConfirmModal: (arg: boolean) => void
  setRemoveShortlistProperty: (arg: boolean) => void
  projectIdentity: ProjectIdentity
  removeShortlistProperty: boolean | 'all'
  available: string
}
type ShortlistHandlerReturnType = [boolean, () => void, boolean]

const ShortlistHandler = ({
  property,
  shortlist,
  setShortlistErrorMessage,
  setRemoveShortlistProperty,
  projectIdentity,
  toggleConfirmModal,
  removeShortlistProperty,
  available,
}: ComponentProps): ShortlistHandlerReturnType => {
  const MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST =
    Number(process.env.REACT_APP_MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST) || 4
  const dispatch = useDispatch()
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const isPropertyShortlisted = React.useMemo(
    () =>
      shortlist.shortlists.some(
        (item) => item.propertyId === property.propertyId
      ),
    [shortlist.shortlists, property]
  )

  const isMatchedConfigShortlist = React.useMemo(
    () =>
      (shortlist.shortlists as LotShortlistInterface[]).some((item) => {
        const propertyConfiguration = property as LotShortlistInterface
        return (
          item.propertyId === property.propertyId &&
          (item.configuration?.floorplan ?? '') ===
            (propertyConfiguration?.configuration?.floorplan ?? '') &&
          (item.configuration?.floorplanOption ?? '') ===
            (propertyConfiguration?.configuration?.floorplanOption ?? '') &&
          (item.configuration?.facade ?? '') ===
            (propertyConfiguration?.configuration?.facade ?? '') &&
          (item.configuration?.facadeColor ?? '') ===
            (propertyConfiguration?.configuration?.facadeColor ?? '') &&
          (item.configuration?.internalTheme ?? '') ===
            (propertyConfiguration?.configuration?.internalTheme ?? '') &&
          (item.configuration?.internalColorScheme ?? '') ===
            (propertyConfiguration?.configuration?.internalColorScheme ?? '')
        )
      }),
    [shortlist.shortlists, property]
  )

  const handleShortlistClick = () => {
    if (available !== projectIdentity?.statusLabels?.available) {
      if (property.type === ShortlistPropertyType.Unit) {
        setShortlistErrorMessage('This unit is not available.')
      }
      if (property.type === ShortlistPropertyType.Lot) {
        setShortlistErrorMessage('This lot is not available.')
      }
      return
    }

    if (
      (property.type === ShortlistPropertyType.Lot &&
        !property.configuration &&
        isPropertyShortlisted) ||
      (property.type === ShortlistPropertyType.Lot &&
        isMatchedConfigShortlist) ||
      (property.type === ShortlistPropertyType.Unit && isPropertyShortlisted)
    ) {
      toggleConfirmModal(true)
      return
    }

    if (shortlist.shortlists.length < MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST) {
      dispatch(addShortlist(property))

      const updatedShortlists: ShortlistItemInterface[] = [
        ...shortlist.shortlists,
        property,
      ]

      firebaseControlQuery.update({
        'shortlist.properties': JSON.parse(JSON.stringify(updatedShortlists)),
      })
      return
    }

    if (property.type === ShortlistPropertyType.Unit) {
      setShortlistErrorMessage(
        `You can shortlist a maximum of ${MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST} units.`
      )
    }
    if (property.type === ShortlistPropertyType.Lot) {
      setShortlistErrorMessage(
        `You can shortlist a maximum of ${MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST} lots.`
      )
    }
  }

  React.useEffect(() => {
    if (removeShortlistProperty) {
      const updatedShortlists = shortlist.shortlists.filter((item) => {
        if (property.type === ShortlistPropertyType.Unit) {
          return property.propertyId !== item.propertyId
        }

        const shortlistItem = item as LotShortlistInterface

        if (
          removeShortlistProperty === 'all' &&
          property?.propertyId === shortlistItem.propertyId
        ) {
          return false
        }

        if (
          property?.propertyId === shortlistItem.propertyId &&
          property?.configuration?.facade ===
            shortlistItem.configuration?.facade &&
          property?.configuration?.facadeColor ===
            shortlistItem.configuration?.facadeColor &&
          property?.configuration?.floorplan ===
            shortlistItem.configuration?.floorplan &&
          property?.configuration?.internalTheme ===
            shortlistItem.configuration?.internalTheme &&
          property?.configuration?.internalColorScheme ===
            shortlistItem.configuration?.internalColorScheme
        ) {
          return false
        }

        return true
      })

      if (
        property.type === ShortlistPropertyType.Lot &&
        removeShortlistProperty === 'all'
      ) {
        dispatch(setShortlists(updatedShortlists))
      }

      if (
        property.type === ShortlistPropertyType.Lot &&
        removeShortlistProperty !== 'all'
      ) {
        dispatch(
          filterShortlist({
            propertyId: property.propertyId,
            type: property.type,
            configuration: property.configuration,
          })
        )
      }

      if (property.type === ShortlistPropertyType.Unit) {
        dispatch(
          filterShortlist({
            propertyId: property.propertyId,
            type: property.type,
          })
        )
      }

      firebaseControlQuery.update({
        'shortlist.properties': JSON.parse(JSON.stringify(updatedShortlists)),
        ...(updatedShortlists.length === 0 && {
          'shortlist.selectedDocumentIds': [],
          'shortlist.selectedAgentId': '',
        }),
      })
      setRemoveShortlistProperty(false)
      toggleConfirmModal(false)
    }
  }, [removeShortlistProperty])

  return [isPropertyShortlisted, handleShortlistClick, isMatchedConfigShortlist]
}

export default ShortlistHandler
