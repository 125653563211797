import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  LotShortlistInterface,
  PackageConfigurationInterface,
  RootStateTypeExtra,
  ShortlistItemInterface,
  ShortlistPropertyType,
} from '@store/types'

export interface ShortlistState {
  shortlists: ShortlistItemInterface[]
}

const INITIAL_STATE = {
  shortlists: [],
} as ShortlistState

const shortlistSlice = createSlice({
  name: 'shortlist',
  initialState: () => {
    const initialState = { ...INITIAL_STATE }

    return initialState
  },
  reducers: {
    addShortlist: (
      state: ShortlistState,
      action: PayloadAction<ShortlistItemInterface>
    ) => {
      const newState: ShortlistState = {
        ...state,
        shortlists: [...state.shortlists, action.payload],
      }
      return newState
    },
    updateShortlist: (
      state: ShortlistState,
      action: PayloadAction<{
        propertyId: ShortlistItemInterface['propertyId']
        data: Partial<ShortlistItemInterface>
      }>
    ) => {
      const newShortlists = [...state.shortlists]
      const matchedIndex = newShortlists.findIndex(
        (item) => item.propertyId === action.payload.propertyId
      )
      if (matchedIndex >= 0) {
        newShortlists[matchedIndex] = {
          ...newShortlists[matchedIndex],
          ...(action.payload.data as ShortlistItemInterface),
          propertyId: action.payload.propertyId,
        }
      }
      const newState: ShortlistState = {
        ...state,
        shortlists: newShortlists,
      }
      return newState
    },
    setShortlists: (
      state: ShortlistState,
      action: PayloadAction<ShortlistItemInterface[]>
    ) => {
      const newState: ShortlistState = {
        ...state,
        shortlists: action.payload,
      }
      return newState
    },
    filterShortlist: (
      state: ShortlistState,
      action: PayloadAction<
        | {
            propertyId: string
            configuration?: never
            type: ShortlistPropertyType.Unit
          }
        | {
            propertyId: string
            configuration?: PackageConfigurationInterface
            type: ShortlistPropertyType.Lot
          }
      >
    ) => {
      const newState: ShortlistState = {
        ...state,
        shortlists: state.shortlists?.filter((item) => {
          if (action.type === ShortlistPropertyType.Unit) {
            return item.propertyId !== action.payload.propertyId
          }

          const lotShortlistItem = item as LotShortlistInterface

          if (
            action.payload.propertyId === lotShortlistItem.propertyId &&
            (action.payload.configuration?.facade ?? '') ===
              (lotShortlistItem.configuration?.facade ?? '') &&
            (action.payload.configuration?.facadeColor ?? '') ===
              (lotShortlistItem.configuration?.facadeColor ?? '') &&
            (action.payload.configuration?.floorplan ?? '') ===
              (lotShortlistItem.configuration?.floorplan ?? '') &&
            (action.payload.configuration?.internalTheme ?? '') ===
              (lotShortlistItem.configuration?.internalTheme ?? '') &&
            (action.payload.configuration?.internalColorScheme ?? '') ===
              (lotShortlistItem.configuration?.internalColorScheme ?? '')
          ) {
            return false
          }

          return true
        }),
      }
      return newState
    },
    removeShortlist: () => ({
      ...INITIAL_STATE,
    }),
  },
})

export const {
  addShortlist,
  updateShortlist,
  setShortlists,
  filterShortlist,
  removeShortlist,
} = shortlistSlice.actions

export const selectShortlist = (state: RootStateTypeExtra) => state.shortlist

export default shortlistSlice.reducer
