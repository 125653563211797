import React from 'react'

import LevelCard from '@components/level-card'

import { Level, Unit } from '@api/building'

interface ComponentPropsInterface {
  levels: Array<{ value: string; label: string }>
  handleClick: (arg: string) => void
  buildingLevels: Array<Level>
  onlyShowAvailable: boolean
  availableStatusLabel: string
}

const LevelList = ({
  levels,
  handleClick,
  buildingLevels,
  onlyShowAvailable,
  availableStatusLabel,
}: ComponentPropsInterface) => {
  const AVAILABLE_STATUS = 'available'
  const getLevelNameFromString = (myString: string) =>
    myString.split('_')?.[1]?.replace(/\s/g, '') ?? ''

  const availableUnitsCount = (level: string) => {
    const units = buildingLevels.filter((item: Level) => item.level === level)
    if (units.length < 1) return 0

    return units[0].data.filter(
      (unit: Unit) =>
        unit.metas.status === availableStatusLabel ||
        unit.metas.status === AVAILABLE_STATUS
    ).length
  }

  if (levels.length < 1)
    return (
      <div className="flex w-full items-center justify-between rounded-lg bg-secondaryColour px-[30px] py-9 text-heading font-medium text-mainColour ">
        No level found
      </div>
    )

  return (
    <div className="flex flex-col gap-[15px]">
      {levels.map((level: any) => (
        <LevelCard
          key={level.value}
          label={`Level ${getLevelNameFromString(level.value)}`}
          handleClick={() => handleClick(level.value)}
          unitCount={availableUnitsCount(getLevelNameFromString(level.value))}
          onlyShowAvailable={onlyShowAvailable}
        />
      ))}
    </div>
  )
}

export default LevelList
