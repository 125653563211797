import { Location } from 'history'
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import type {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import { Status } from '@components/data-handler/data-handler'
import Filter from '@components/filter'
import FloorPlateGallery from '@components/floor-plate-gallery'
import { InfoModal } from '@components/modals'
import { PannellumDataInterface as PanoramicDataInterface } from '@components/showcase-pannellum/types'
import SlideshowControl from '@components/slideshow-control'
import UnitListing from '@components/unit-listing'

import {
  Level,
  Unit,
  selectFromResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  selectFromResult as selectFromExternalLinkResult,
  useGetExternalLinksByPropertyQuery,
} from '@api/external-links'
import {
  FloorPlateGalleryInterface,
  selectFromResult as selectFromFloorGalleryResult,
  useGetFloorPlateGalleryQuery,
} from '@api/floor-plate-gallery'
import {
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'
import { getTabIndexByKey, getTabKeyByIndexBy } from '@utilities/floor-tab'
import { isNotSessionSettingsOrTools } from '@utilities/helper'

import { ChevronSvg, FunnelSvg, PanoramaSvg } from '@svg/react'

import BuildingSkeleton from './building-skeleton'
import FloorGalleryTab from './floor-gallery-tab'
import UnitDetailsView from './unit-details-view'
import UnitGallery from './unit-gallery'

export interface BuildingProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

const Building = ({ projectIdentity, session }: BuildingProps) => {
  const history = useHistory()
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [buildingPageTitle, setBuildingPageTitle] = React.useState('')
  const [floorGalleryActiveTab, setFloorGalleryActiveTab] =
    React.useState('floor')
  const [activeBlock, setActiveBlock] = React.useState('')
  const [activeLevel, setActiveLevel] = React.useState('')
  const [activeUnit, setActiveUnit] = React.useState('')
  const [unitSelected, setUnitSelected] = React.useState<Unit>()
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [units, setUnits] = React.useState<Array<Unit>>([])
  const [unitGalleryItemLength, setUnitGalleryItemLength] = React.useState(0)
  const [shouldShowSlideshowBtn, setShouldShowSlideShowBtn] =
    React.useState(false)
  const [disableSlideShowButton, setDisableSlideShowButton] =
    React.useState<boolean>(false)
  const [toggleExternalLinkPopup, setToggleExternalLinkPopup] =
    React.useState(false)

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult }
  )

  const floorGalleryPayload = useGetFloorPlateGalleryQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromFloorGalleryResult }
  )

  const { floorPlateGalleryData } = floorGalleryPayload

  const { panoramicData } = useGetPanoramicQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromPanoramicResult }
  )

  const { levels, isLoaded } = buildingPayload

  const externalLinksPayload = useGetExternalLinksByPropertyQuery(
    { projectName: projectIdentity.projectId, propertyId: activeUnit },
    {
      skip: !activeUnit,
      selectFromResult: selectFromExternalLinkResult,
    }
  )

  const { externalLinks } = externalLinksPayload

  const handleUnits = () => {
    setUnits(
      levels
        .find((lvl: Level) => lvl.level === activeLevel)
        ?.data.filter((unit: Unit) => {
          if (activeBlock) {
            return unit.blockId === activeBlock
          }
          return unit
        }) || []
    )
  }

  const handleInfoPopup = async () => {
    await firebaseControlQuery.update({
      [`building.unitInfoPopup`]: !session?.building.unitInfoPopup,
    })
  }

  const handleExternalLinkPopup = async () => {
    await firebaseControlQuery.update({
      [`building.externalLinkPopup`]: !toggleExternalLinkPopup,
    })
  }

  const handleUnitClick = (unit: Unit) => {
    toggleFilter(false)
    firebaseControlQuery.update({ [`building.activeUnit`]: unit.name })
  }

  const removeUnit = async (newPathname?: string) => {
    let removeUnitPayload: Record<string, unknown> = {
      [`building.unitInfoPopup`]: false,
      [`unitGallery.galleryControl.activeItemID`]: 0,
      [`unitGallery.galleryControl.isPlaying`]: false,
      [`unitGallery.playerControl.playerState`]: 'stop',
      [`unitGallery.playerControl.isPlayerVisible`]: false,
    }

    if (newPathname !== '/panoramic') {
      removeUnitPayload = {
        ...removeUnitPayload,
        [`building.activeUnit`]: '',
      }
    }

    await firebaseControlQuery.update(removeUnitPayload)
  }

  const handleBackButtonPress = async () => {
    let route = 'level-view'
    if (projectIdentity.isSnaploaderActive) route = 'snaploader-view'
    if (projectIdentity.isEnvisionVRActive) route = '3d-building'

    await firebaseControlQuery.update({
      [`activeRoute`]: route,
    })

    await firebaseControlQuery.update({
      [`building.activeLevel`]: '',
      [`building.galleryControl.activeItemID`]: 0,
      [`floorGallery.galleryControl.activeTabIndex`]: 0,
      [`floorGallery.playerControl.playerState`]: 'stop',
      [`floorGallery.playerControl.isPlayerVisible`]: false,
    })
  }

  const handleBuildingPageTitle = () => {
    if (activeUnit) {
      setBuildingPageTitle(`Unit ${activeLevel}-${activeUnit}`)
      return
    }
    setBuildingPageTitle(`Level ${activeLevel}`)
  }

  const findPanoramic = (
    type: 'unit' | 'level' | 'block',
    targetName: string
  ) =>
    panoramicData.find(
      (data: PanoramicDataInterface) =>
        data.type === type &&
        (data.panoramaGroup || '')?.toString() === targetName
    )

  const hasPanoramicPayload = () => {
    if (activeUnit !== '') {
      return findPanoramic('unit', activeUnit?.toString() || '') !== undefined
    }

    if (activeLevel !== '') {
      return findPanoramic('level', activeLevel?.toString() || '') !== undefined
    }

    if (activeBlock !== '') {
      return findPanoramic('block', activeBlock?.toString() || '') !== undefined
    }

    return false
  }

  const handleFloorGalleryTabClick = async (tabKey: string) => {
    await firebaseControlQuery.update({
      [`floorGallery.galleryControl.activeItemID`]: 0,
      [`floorGallery.playerControl.playerState`]: 'stop',
      [`floorGallery.playerControl.isPlayerVisible`]: false,
      [`floorGallery.galleryControl.activeTabIndex`]: getTabIndexByKey(tabKey),
    })
  }

  const floorGalleryItemsCount = () => {
    let foundData = null

    if (activeBlock && activeLevel) {
      foundData = floorPlateGalleryData.find(
        (value: FloorPlateGalleryInterface) =>
          value.block.toString() === activeBlock.toString() &&
          value.level.toString() === activeLevel.toString()
      )
    } else if (activeLevel) {
      foundData = floorPlateGalleryData.find(
        (value: FloorPlateGalleryInterface) =>
          value.level.toString() === activeLevel.toString()
      )
    }

    const floorAssets = foundData?.assets ?? []

    return floorAssets.length
  }

  const handleGoToPanoramic = async () => {
    await firebaseControlQuery.update({
      [`panoramic.scene`]: '',
    })
    firebaseControlQuery.updateRoute('panoramic')
    history.push('panoramic')
  }

  React.useEffect(() => {
    handleBuildingPageTitle()
  }, [activeLevel, activeUnit])

  React.useEffect(() => {
    if (Object.keys(levels).length > 0) {
      handleUnits()
    }
  }, [activeBlock, activeLevel, isLoaded, levels])

  React.useEffect(() => {
    const unit = units.find((res) => res.name === activeUnit)
    setUnitSelected(unit)
  }, [activeUnit, units])

  React.useEffect(() => {
    if (session) {
      const {
        building: {
          activeBlock: firebaseActiveBlock,
          activeLevel: firebaseActiveLevel,
          activeUnit: firebaseActiveUnit,
          externalLinkPopup: externalLinkPopupFirebase,
        },
        floorGallery: {
          galleryControl: {
            activeTabIndex: firebaseActiveFloorGalleryTabIndex,
          },
        },
        unitGallery: {
          galleryControl: { isPlaying: firebaseIsPlaying },
          playerControl: { playerState: firebasePlayerState },
        },
      } = session

      if (
        firebaseActiveUnit === '' &&
        (firebaseIsPlaying || firebasePlayerState === 'play')
      ) {
        firebaseControlQuery.update({
          [`unitGallery.galleryControl.activeItemID`]: 0,
          [`unitGallery.galleryControl.isPlaying`]: false,
          [`unitGallery.playerControl.playerState`]: 'stop',
          [`unitGallery.playerControl.isPlayerVisible`]: false,
        })
      }

      setActiveBlock(firebaseActiveBlock)
      setActiveLevel(firebaseActiveLevel)
      setActiveUnit(firebaseActiveUnit)
      setToggleExternalLinkPopup(externalLinkPopupFirebase)

      setFloorGalleryActiveTab(
        getTabKeyByIndexBy(firebaseActiveFloorGalleryTabIndex)
      )
      setDisableSlideShowButton(firebasePlayerState === 'play')
    }
  }, [session])

  React.useEffect(() => {
    setShouldShowSlideShowBtn(unitGalleryItemLength > 1)
  }, [unitGalleryItemLength])

  React.useEffect(() => {
    const removeUnitOnRouteChange = (location: Location) => {
      const { pathname } = location
      firebaseControlQuery.update({
        [`building.externalLinkPopup`]: false,
      })
      if (isNotSessionSettingsOrTools(pathname)) {
        removeUnit(pathname).catch((error) => console.error(error))
      }
    }

    const routeChangeUnsubscribe = history.listen(removeUnitOnRouteChange)

    return () => {
      routeChangeUnsubscribe()
    }
  }, [history])

  const dataHandlerPayload = React.useMemo(() => {
    if (externalLinksPayload.status === Status.UNINITIALIZED) {
      return buildingPayload
    }

    return externalLinksPayload
  }, [externalLinksPayload, buildingPayload])

  return (
    <Container>
      <DataHandler
        message="There are no available units."
        payload={{
          ...dataHandlerPayload,
          data: buildingPayload.levels,
        }}
        skeletonFrame={<BuildingSkeleton />}
      >
        {session && (
          <Filter
            toggle={toggleFilter}
            isOpen={isFilterOpen}
            firebaseUnitFilter={session.building.unitFilter}
            firebaseActiveBlock={session.building.activeBlock}
            firebaseActiveLevel={session.building.activeLevel}
          />
        )}
        <div className="h-full w-full px-4">
          <div className="h-top-bar w-full py-3 text-neutralColour">
            <div className="flex h-[40%] items-start justify-between text-default font-normal">
              <button
                onClick={() => {
                  if (activeUnit) {
                    removeUnit()
                    return
                  }
                  handleBackButtonPress()
                }}
                type="button"
                className="relative inline-flex items-center"
              >
                <ChevronSvg className="absolute -left-2.5 h-8 w-8" />

                <span className="ml-6">
                  {activeUnit ? `Level ${activeLevel}` : 'Building'}
                </span>
              </button>
              {!activeUnit &&
                (floorGalleryItemsCount() > 0 || hasPanoramicPayload()) && (
                  <div className="flex items-center">
                    {floorGalleryItemsCount() > 0 && (
                      <FloorGalleryTab
                        activeTab={floorGalleryActiveTab}
                        handleClick={handleFloorGalleryTabClick}
                      />
                    )}
                    {hasPanoramicPayload() && (
                      <button
                        onClick={handleGoToPanoramic}
                        type="button"
                        className="text-md ml-2 rounded-lg border border-mainColour bg-white px-3 py-2 tracking-widest text-mainColour"
                      >
                        Panoramic view
                      </button>
                    )}
                  </div>
                )}
              {activeUnit && hasPanoramicPayload() && (
                <button
                  onClick={handleGoToPanoramic}
                  type="button"
                  className="inline-flex items-center"
                >
                  <span className="mr-1">Panoramic view</span>
                  <PanoramaSvg className="h-8 w-8" />
                </button>
              )}
            </div>
            <div className="flex h-[60%] items-baseline justify-between">
              <div className="text-title font-medium">{buildingPageTitle}</div>
              {activeUnit && shouldShowSlideshowBtn && (
                <SlideshowControl
                  galleryName="unitGallery"
                  isDisabled={disableSlideShowButton}
                />
              )}
              {!activeUnit &&
                !projectIdentity.hideFilter &&
                floorGalleryActiveTab === 'floor' && (
                  <button
                    type="button"
                    onClick={() => toggleFilter(true)}
                    className="inline-flex items-center text-default"
                  >
                    <span className="mr-1">Filters</span>
                    <FunnelSvg className="h-6 w-6" />
                  </button>
                )}
            </div>
          </div>

          <div className="no-scrollbar relative h-page-body w-full overflow-auto">
            <TransitionGroup>
              <CSSTransition
                key={unitSelected?.id || floorGalleryActiveTab}
                classNames={
                  activeUnit || floorGalleryActiveTab === 'gallery'
                    ? 'transition-slide-left'
                    : 'transition-slide-right'
                }
                timeout={300}
                exit={false}
                onEntered={(node: HTMLElement) =>
                  node.classList.remove('transition-slide-left-enter-done')
                }
              >
                <div className="absolute h-full w-full">
                  <>
                    {unitSelected && (
                      <UnitDetailsView
                        unit={unitSelected}
                        handleInfoPopup={handleInfoPopup}
                        unitInfoPopup={Boolean(session?.building.unitInfoPopup)}
                        showPrice={projectIdentity.showPrice}
                        hideSoldPrice={projectIdentity.hideSoldPrice}
                        handleExternalLinkPopup={handleExternalLinkPopup}
                        showExternalLink={externalLinks?.length > 0}
                        toggleExternalLinkPopup={toggleExternalLinkPopup}
                        availableStatusLabel={
                          projectIdentity?.statusLabels?.available
                        }
                      >
                        <UnitGallery
                          hasBlurredEffect
                          imgClass="object-cover !h-auto"
                          key={unitSelected.id}
                          projectIdentity={projectIdentity}
                          unit={unitSelected}
                          unitGalleryControlSession={session?.unitGallery}
                          getUnitGalleryItemLength={setUnitGalleryItemLength}
                          onlyShowUnitGalleries={
                            projectIdentity.onlyShowUnitGalleries
                          }
                        />
                      </UnitDetailsView>
                    )}

                    {!unitSelected &&
                      session &&
                      floorGalleryActiveTab === 'floor' &&
                      session.activeRoute === 'building' && (
                        <UnitListing
                          units={units}
                          trigger={handleUnitClick}
                          filter={session.building.unitFilter}
                          disableSoldUnit={projectIdentity?.disableSoldUnit}
                        />
                      )}
                    {!unitSelected &&
                      floorGalleryActiveTab === 'gallery' &&
                      floorPlateGalleryData.length > 0 && (
                        <FloorPlateGallery
                          floorPlateGalleryData={floorPlateGalleryData}
                          floorGalleryControlSession={session?.floorGallery}
                          activeLevel={activeLevel}
                          activeBlock={activeBlock}
                        />
                      )}
                  </>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </DataHandler>

      <InfoModal
        isVisible={toggleExternalLinkPopup}
        toggleModal={handleExternalLinkPopup}
        title="External link selected"
        message="An external site has been launched. Please use the main Showcase to continue navigating."
      />
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(Building)
